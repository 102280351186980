import XHR from "../services/index";

// 获取钉钉免密登录信息
export async function fetchDingTalkConfig() {
  return XHR({
    url: `/message/dingtalkH5/dingTalk/getConfig?url=${window.location.href.replace(window.location.hash, "")}`,
    method: 'POST'
  });
}

// 获取登录用户信息
export async function fetchUserInfo(authCode) {
  return XHR({
    url: `/message/dingtalkH5/dingTalk/getUserInfo?authCode=${authCode}`,
    method: 'POST'
  });
}