import React, {Suspense} from 'react';
import {HashRouter, Navigate, Route, Routes} from "react-router-dom";
import * as dd from 'dingtalk-jsapi';
import routes from './routes';
import BasicLayout from "@/layout/basic";
import PageLoading from "@/components/public/PageLoading";
import {Exception404} from "@/pages/exception";

const DomTitle = ({item}) => {
    const title = item.title || process.env.TITLE;
    document.title = title;
    dd.biz.navigation.setTitle({title});
    return <item.component/>
}

const App = () => {
    return (
        <HashRouter>
            <Routes>
                <Route path="/" element={<BasicLayout/>}>
                    {routes.map((item, index) => {
                        return (
                            <Route
                                key={`routers${index}`}
                                exact
                                path={item.path}
                                element={
                                    <Suspense fallback={<PageLoading />}>
                                        <DomTitle item={item}/>
                                    </Suspense>
                                }
                            />
                        )
                    })}
                    <Route path="/" element={<Navigate replace to="/approve"/>}/>
                </Route>
                <Route path="*" element={<Exception404/>}/>
            </Routes>
        </HashRouter>
    )
}

export default App;