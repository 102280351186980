let Headers = {
  "Content-Type": "application/json",
  "Cache-Control": "no-cache",
  Accept: "*/*",
};

export const setHeaders = (params) => {
  Headers = {...Headers, ...params};
};

export const getHeaders = (dataType) => {
  let _dataType = '';
  switch (dataType) {
    case "form":
      _dataType = 'application/x-www-form-urlencoded';
      break;
    case "multipart":
      _dataType = 'multipart/form-data';
      break;
    case "json":
      _dataType = 'application/json';
      break;
    case "xml":
      _dataType = 'text/xml';
      break;
    case "text":
      _dataType = 'text/plain';
      break;
    case "download":
      _dataType = 'application/octet-stream';
      break;
    default:
      _dataType = 'application/json';
      break;
  }

  // username中文编码
  let obj = {
    dingtalkH5Token: JSON.parse(window.sessionStorage.getItem('token')),
  };
  if (obj.dingtalkH5Token) {
    Headers = {...Headers, ...obj};
  } else if (Headers.dingtalkH5Token) {
    delete Headers.dingtalkH5Token;
  }
  Headers["Content-Type"] = _dataType;
  return Headers;
};