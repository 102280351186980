import React from 'react';
import "./index.css"

function PageLoading() {
    return (
        <div className="ant-spin-nested-loading">
            <div>
                <div className="ant-spin ant-spin-lg ant-spin-spinning">
                    <span className="ant-spin-dot ant-spin-dot-spin">
                        <i className="ant-spin-dot-item"></i>
                        <i className="ant-spin-dot-item"></i>
                        <i className="ant-spin-dot-item"></i>
                        <i className="ant-spin-dot-item"></i>
                    </span>
                    <div className="ant-spin-text">Loading...</div>
                </div>
            </div>
        </div>
    )
}

export default PageLoading;