import {fetchDingTalkConfig, fetchUserInfo} from "@/api/global";
import * as dd from 'dingtalk-jsapi';

export const getAppToken = () => {
    fetchDingTalkConfig().then(res => {
        const {result} = res;
        dd.runtime.permission.requestAuthCode({
            corpId: result.corpId,
            onSuccess: function (info) {
                fetchUserInfo(info.code).then((result) => {
                    window.sessionStorage.setItem('token', result.result.dingtalkH5Token);
                }).catch((err) => {
                    console.log('获取token信息失败: ' + JSON.stringify(err));
                });
            },
            onFail: function (err) {
                console.log('免登授权码获取失败: ' + JSON.stringify(err));
            }
        }).catch(err => {console.log(err)});
    }).catch((err) => {
        console.log('获取JS鉴权信息失败: ' + JSON.stringify(err));
    })

};