/**
 * 参数对象转url参数
 * @param param
 * @param key
 * @param encode
 * @returns {string}
 */
function urlEncode(param, key, encode) {
  if (param == null) return '';
  let paramStr = '';
  let t = typeof (param);
  if (t === 'string' || t === 'number' || t === 'boolean') {
    paramStr += '&' + key + '=' + ((encode == null || encode) ? encodeURIComponent(param) : param);
  } else {
    let formBody = [];
    for (let property in param) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(param[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    paramStr = formBody.join("&");
  }
  return paramStr;
}

/**
 * 解析url参数
 * @param {String} str url
 * @returns {Array} 参数列表
 */
function parseQueryString(str) {
    var reg = /(([^?&=]+)(?:=([^?&=]*))*)/g;
    var result = {};
    var match;
    var key;
    var value;
    while ((match = reg.exec(str))) {
        key = match[2];
        value = match[3] || "";
        try {
            result[key] = decodeURIComponent(value);
        } catch (error) {
            console.log('error', error);
            window.location.href = '/#/404';
        }
    }
    return result;
}

const browser={
    versions:function(){
        var u = navigator.userAgent,app = navigator.appVersion;
        return {
            trident: u.indexOf('Trident') > -1,
            presto: u.indexOf('Presto') > -1,
            webKit: u.indexOf('AppleWebKit') > -1,
            gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1,
            mobile: !!u.match(/AppleWebKit.*Mobile.*/)||!!u.match(/AppleWebKit/),
            ios: !!u.match(/(i[^;]+;(U;)? CPU.+Mac OS X)/),
            android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1,
            iPhone: u.indexOf('iPhone') > -1 || u.indexOf('Mac') > -1,
            iPad: u.indexOf('iPad') > -1,
            webApp: u.indexOf('Safari') == -1
        }
    }()
}

export {
  urlEncode,
  parseQueryString,
  browser
}