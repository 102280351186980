import {lazy} from 'react';

const routes = [
    {
        path: 'approve',
        component: lazy(() => import('@/pages/Approve')),
        title: '审批详情'
    }
]

export default routes;