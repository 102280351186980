import {useImmerReducer} from "use-immer";

function GlobalReducer() {
  const [state, dispatch] = useImmerReducer(reducer, {
    env: {}
  });

  function reducer(draft, action) {
    switch (action.type) {
      case "setEnv":
        return {...draft, env: {...draft.env, ...action.data}};
      default:
        throw new Error('未定义的reducer命令')
    }
  }

  return {
    state,
    dispatch,
  }
}

export default GlobalReducer;