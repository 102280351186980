import React, {useEffect, createContext} from 'react';
import {Outlet} from "react-router";
import {Exception403} from "@/pages/exception";
import PageLoading from "@/components/public/PageLoading";
import * as dd from 'dingtalk-jsapi';
import {useSessionStorage} from "@/hooks";
import GlobalReducer from "../reducers/global";
import {fetchDingTalkConfig, fetchUserInfo} from "@/api/global";

const GlobalContext = createContext({});

function BasicLayout() {
    const {state, dispatch} = GlobalReducer();
    const isDev = process.env.NODE_ENV === 'development';
    const isInDD = dd.env.platform !== 'notInDingTalk';
    const [token, setToken] = useSessionStorage('token', '');

    useEffect(() => {
        dispatch({
            type: 'setEnv', data: {
                isInDD: isInDD,
                isMobile: dd.ios || dd.android,
                isPc: dd.pc || dd.other,
                isIOS: dd.ios,
                isAndroid: dd.android,
            }
        });
        setToken('');
        if (process.env.TOKEN) {
            setToken(process.env.TOKEN);
        } else if (isInDD) {
            fetchDingTalkConfig().then(res => {
                const {result} = res;
                dd.runtime.permission.requestAuthCode({
                    corpId: result.corpId,
                    onSuccess: function (info) {
                        fetchUserInfo(info.code).then((result) => {
                            setToken(result.result.dingtalkH5Token);
                        });
                    },
                    onFail: function (err) {
                        console.log('免登授权码获取失败: ' + JSON.stringify(err));
                    }
                }).catch(err => {
                    console.log(err)
                });
            }).catch((err) => {
                console.log('获取JS鉴权信息失败: ' + JSON.stringify(err));
            })
        }
    }, [])

    return (
        <div className="container">
            <GlobalContext.Provider value={{state, dispatch}}>
                {isDev && token ? <Outlet/> : isInDD ? token ? <Outlet/> : <PageLoading/> : <Exception403/>}
            </GlobalContext.Provider>
        </div>
    );
}

export {
    GlobalContext
};
export default BasicLayout;