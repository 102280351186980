import React, {useContext} from "react";
import {Button} from 'dingtalk-design-mobile';
import * as dd from 'dingtalk-jsapi';
import {GlobalContext} from "@/layout/basic";
import pic_403 from "../../assets/images/global/403.png";
import styles from "./index.scss";

const CTS_URL = process.env.REACT_APP_ORIGIN;

function Exception403() {
    const {state: {env}} = useContext(GlobalContext);

    const toCTS = () => {
        if (env.isInDD) {
            dd.biz.util.openLink({url: CTS_URL})
        } else {
            window.open(CTS_URL)
        }
    };

    return (
        <div className={styles.exceptionBox}>
            <div>
                <img src={pic_403} alt=""/>
                <p>请在钉钉打开本页面</p>
                {env.isPc && <Button inline type="primary" size='small' className={styles.btn} onClick={toCTS}>前往CTS系统</Button>}
            </div>
        </div>
    );
}

function Exception404() {
    const {state: {env}} = useContext(GlobalContext);

    const toCTS = () => {
        if (env.isInDD) {
            dd.biz.util.openLink({url: CTS_URL})
        } else {
            window.open(CTS_URL)
        }
    };

    return (
        <div className={styles.exceptionBox}>
            <div>
                <img src={pic_403} alt=""/>
                <p>未找到页面</p>
                {env.isPc && <Button inline type="primary" size='small' className={styles.btn} onClick={toCTS}>前往CTS系统</Button>}
            </div>
        </div>
    );
}

export {
    Exception403,
    Exception404
};