import {Toast} from "dingtalk-design-mobile";
import {urlEncode} from "@/utils";
import {getHeaders} from "@/utils/headers";
import {getAppToken} from "@/layout/utils";

const prefix = '/cts/v1.0';
const REACT_APP_ORIGIN = process.env.REACT_APP_ORIGIN;

const xhr = ({url, body = null, method = "POST", dataType = "json", signal}) => {

    const param = {
        method: method,
        mode: 'cors',
        credentials: 'omit',
        headers: getHeaders(dataType)
    };

    url = prefix + url;
    url = REACT_APP_ORIGIN ? REACT_APP_ORIGIN + url : url;

    // fetch支持取消请求
    if (signal) {
        param.signal = signal;
    } else if (body && body.signal) {
        param.signal = body.signal;
        delete body.signal;
    }

    function parseRequest(response) {
        const {code, message, result} = response;
        if (code === 200) {
            return {code, message, result};
        }
        if (code === 500) {
            Toast.fail({content: '加载失败', position: 'middle'})
        }
        if (code === 1001) {
            getAppToken();
            Toast.info({content: '网络不稳定，请重试', position: 'middle'});
        }
        return Promise.reject(response);
    }

    function parseJSON(response) {
        return response.json();
    }

    if (method === "POST" || method === "DELETE" || method === "PUT") {
        body = dataType === 'form' ? urlEncode(body) : JSON.stringify(body);
    }

    if (body) {
        if (method === "GET") {
            url = `${url}?${urlEncode(body)}`
        } else {
            param.body = body
        }
    }

    // param.credentials = 'include';

    return fetch(url, param).then(parseJSON).then(parseRequest);
};

export default xhr;
